import getData from "@/__main__/get-data.mjs";
import LolProbuildMatches from "@/game-lol/models/lol-probuild-matches.mjs";
import LolProbuildsChampionsReport from "@/game-lol/models/lol-probuilds-champions-report.mjs";
import * as API from "@/game-lol/utils/api.mjs";
import { getDefaultedFiltersForProBuilds } from "@/game-lol/utils/util.mjs";

const PER_PAGE = 10;

function fetchData(_params, searchParams, state) {
  const filters = getDefaultedFiltersForProBuilds(searchParams);
  const currPage = state?.page || 1;
  const role = filters.lane || filters.role;

  const promises = [];

  promises.push(
    getData(
      API.getProMatchHistory({
        lane: role,
        first: PER_PAGE * currPage,
      }),
      LolProbuildMatches,
      ["lol", "proBuildMatchlist", role],
      {
        shouldFetchIfPathExists: true,
      },
    ),
  );

  promises.push(
    getData(
      API.getProbuildChampionsReport(),
      LolProbuildsChampionsReport,
      ["lol", "proBuildChampionsReport"],
      {
        shouldFetchIfPathExists: false,
      },
    ),
  );

  return Promise.all(promises);
}

export default fetchData;
